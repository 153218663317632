















































import {
  Snackbar,
  useUiStateStore
} from '@/@core/services/uiStateStore';
import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  PropType,
  computed
} from '@vue/composition-api';
import { breakpoints, isMobile } from '@/@core/helpers/useBreakpoint';

export default defineComponent({
  name: 'KSnackbar',
  props: {
    notification: {
      type: Object as PropType<Snackbar>,
      required: true
    }
  },
  setup(props) {
    const showNotification: Ref<boolean> = ref(true);
    const timeout: Ref<number> = ref(4000);
    const color: Ref<string> = ref('');
    const progress = ref(0);
    const uiState = useUiStateStore();

    const initNotification = () => {
      const hasTimeout = props?.notification.timeout;
      const hasColor = props?.notification.color;

      if (hasColor) color.value = props.notification.color || '';
      if (hasTimeout)
        timeout.value = props.notification.timeout || 4000;

      progress.value = timeout.value;
      const interval = setInterval(() => {
        progress.value -= 100;
        if (progress.value <= 0) {
          clearInterval(interval);
          uiState.removeSnackbar(props.notification);
        }
      }, 100);
    };

    const calculateProgress = computed(
      () =>
        ((timeout.value - progress.value) / timeout.value) * 100 +
          6 || 100
    );

    onMounted(() => initNotification());

    return {
      showNotification,
      timeout,
      color,
      breakpoints,
      calculateProgress,
      isMobile
    };
  }
});
