import { Ref, unref } from "@vue/composition-api";
import { useUiStateStore } from "../services/uiStateStore"

export default function useErrorMessageHandler(error: any | Ref<any>) { 
    const unrefedError = unref(error)
    let message = unrefedError.message

    if (unrefedError?.response) message = unrefedError.response.data.message
    console.error('errorMessage:',message);

    useUiStateStore()
        .createSnackbar({
            message,
            color: 'error',
            timeout: 10000
        })

    return null
}

export {
    useErrorMessageHandler
}
