import { computed, ref, Ref, unref } from '@vue/composition-api';
import axios, { AxiosRequestConfig } from 'axios';

type CallApiOptions = {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  url: string;
  data?: any;
  config?: AxiosRequestConfig | undefined;
};

function setTokenToLocalStorage(token: string) {
  if (token) localStorage.setItem('adiId', token);
}

function axiosInstance() {
  const token = localStorage.getItem('adiId');
  const protocol = window.location.protocol + '//';
  const hostname = window.location.hostname;
  const port = 4021;
  const url =
    process.env.VUE_APP_API_BASE_URL ?? `${hostname}:${port}`;
  const fullUrl = `${protocol}${url}`;

  const instance = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost:4021',
    baseURL: fullUrl,
    headers: {
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    withCredentials: true
  });

  if (token)
    instance.defaults.headers.common['Authorization'] = token;

  return instance;
}

async function useCallApi({
  method,
  url,
  data,
  config
}: CallApiOptions) {
  const response: Ref<any> = ref(null);
  const result: Ref<any> = ref(null);
  const error: Ref<any> = ref(null);
  const errorMessage: Ref<string> = ref('');

  try {
    //@ts-ignore
    response.value = await axiosInstance()[method](url, data, config);
    if (response.value) result.value = response.value.data;

    const hasToken = result.value.token;
    if (hasToken) setTokenToLocalStorage(result.value.token);
  } catch (err: any) {
    error.value = err;
    errorMessage.value = err.message;
  } finally {
    return {
      result: unref(result),
      error: unref(error),
      errorMessage: unref(errorMessage),
      response: unref(error)
    };
  }
}

export { useCallApi };
