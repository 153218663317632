export const removeItem = <T>(array: Array<T>, value: string): Array<T> => { 
    const updatedArray = array.filter((arr: any) => arr.id !== value)

    return updatedArray
}

export const updateItem = <T>(array: Array<T>, item: any): Array<T> => { 
    let removedArray: any[] = removeItem(array, item.id)
    let updatedArray: any[] = [item, ...removedArray]

    return updatedArray
}

export const sortArray = <T>(array: Array<T>, sortBy: any, isDescending: boolean = false): Array<T> => {
    let sortedArray = [...array].sort((a: any, b: any): any => {
        let firstCompare = a
        let secondCompare = b

        if (isDescending) {
            firstCompare = b
            secondCompare = a
        }

        return firstCompare[sortBy].localeCompare(secondCompare[sortBy])
    })

    return sortedArray
}

export const getObjectValue = (obj: any, path: string): any => {  
    if (obj === null || obj === undefined) return null 
    if (!path) return obj;
    const properties: any[] = path.split('.');

    return getObjectValue(obj[properties.shift()], properties.join('.'))
}

export const findIndex = (array: any[], key: string, value: any) => {
    return array.findIndex((element, index) => {
        if (element[key] === value) return true
    })
}