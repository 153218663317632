import useLazyloadComponent from '@/@core/helpers/lazyloadComponent';
import useFixRouter from '@/@core/services/fixRouter';
import { pinia } from '@/main';
import { useAuthStore } from '@/modules/auth/services/store';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);
useFixRouter(VueRouter);

const path: any = {
  landing: 'modules/landing',
  auth: 'modules/auth',
  dashboard: 'modules/dashboard',
  industry: 'modules/industry',
  classification: 'modules/classification',
  unit: 'modules/unit',
  commodity: 'modules/commodity',
  marketingArea: 'modules/marketing-area',
  finance: 'modules/finance',
  user: 'modules/user',
  meta: 'modules/meta',
  map: 'modules/map',
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'LandingPage',
    component: () =>
      useLazyloadComponent(
        `${path.landing}/pages/default-page/index.vue`
      )
  },
  {
    path: '/produk',
    name: 'LandingProduct',
    meta: {
      breadcrumb: 'List Produk'
    },
    component: () =>
      useLazyloadComponent(
        `${path.landing}/pages/product-page/index.vue`
      )
  },
  {
    path: '/industri',
    name: 'LandingIndustry',
    meta: {
      breadcrumb: 'List Industri'
    },
    component: () =>
      useLazyloadComponent(
        `${path.landing}/pages/industry-page/index.vue`
      )
  },
  {
    path: '/industri/:id',
    name: 'LandingIndustryDetail',
    props: true,
    component: () =>
      useLazyloadComponent(
        `${path.landing}/pages/industry-detail-page/index.vue`
      )
  },
  {
    path: '/masuk',
    name: 'LoginPage',
    component: () =>
      useLazyloadComponent(`${path.auth}/pages/login-page/index.vue`)
  },
  {
    path: '/daftar',
    name: 'RegisterPage',
    component: () =>
      useLazyloadComponent(
        `${path.auth}/pages/register-page/index.vue`
      )
  },
  {
    path: '/app',
    name: 'Utama',
    component: () =>
      useLazyloadComponent(`@core/layouts/AppLayout.vue`),
    meta: {
      requiresAuth: true,
      breadcrumb: 'Utama'
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        meta: {
          breadcrumb: 'Dashboard'
        },
        component: () =>
          useLazyloadComponent(
            `${path.dashboard}/pages/default-page/index.vue`
          )
      },
      {
        path: 'industri',
        name: 'Data Industri',
        meta: {
          breadcrumb: 'Data Industri'
        },
        component: () =>
          useLazyloadComponent(`@core/layouts/DefaultLayout.vue`),
        children: [
          {
            path: '',
            name: 'Data Industri',
            meta: {
              breadcrumb: 'Data Industri'
            },
            component: () =>
              useLazyloadComponent(
                `${path.industry}/pages/default-page/index.vue`
              )
          },

          {
            path: 'import',
            name: 'Import Industri',
            meta: {
              breadcrumb: 'Import Industri'
            },
            component: () =>
              useLazyloadComponent(
                `${path.industry}/pages/import-page/index.vue`
              )
          },
          {
            path: 'tambah',
            name: 'Tambah Industri',
            meta: {
              breadcrumb: 'Tambah Industri'
            },
            component: () =>
              useLazyloadComponent(`@core/layouts/DefaultLayout.vue`),
            children: [
              {
                path: '',
                name: 'Tambah Industri',
                meta: {
                  breadcrumb: 'Tambah Industri'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/form-page/index.vue`
                  )
              },
              {
                path: 'umum',
                name: 'Data Umum',
                meta: {
                  breadcrumb: 'Data Umum'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/common-form-page/index.vue`
                  )
              }
            ]
          },
          {
            path: ':id',
            name: 'Edit Industri',
            props: true,
            meta: {
              breadcrumb: 'Edit Industri'
            },
            component: () =>
              useLazyloadComponent(`@core/layouts/DefaultLayout.vue`),
            children: [
              {
                path: '',
                name: 'Edit Industri',
                props: true,
                meta: {
                  breadcrumb: 'Edit Industri'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/form-page/index.vue`
                  )
              },
              {
                path: 'umum',
                name: 'Data Umum',
                props: true,
                meta: {
                  breadcrumb: 'Data Umum'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/common-form-page/index.vue`
                  )
              },
              {
                path: 'izin',
                name: 'Perizinan',
                props: true,
                meta: {
                  breadcrumb: 'Perizinan'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/permission-form-page/index.vue`
                  )
              },
              {
                path: 'produk',
                name: 'Produk',
                props: true,
                meta: {
                  breadcrumb: 'Produk'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/product-form-page/index.vue`
                  )
              },
              {
                path: 'nilai-investasi',
                name: 'Nilai Investasi',
                props: true,
                meta: {
                  breadcrumb: 'Nilai Investasi'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/investment-form-page/index.vue`
                  )
              },
              {
                path: 'material',
                name: 'Material',
                props: true,
                meta: {
                  breadcrumb: 'Material'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/material-form-page/index.vue`
                  )
              },
              {
                path: 'operasional',
                name: 'Operasional',
                props: true,
                meta: {
                  breadcrumb: 'Operasional'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/operational-form-page/index.vue`
                  )
              },
              {
                path: 'gaji',
                name: 'Gaji',
                props: true,
                meta: {
                  breadcrumb: 'Gaji'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/wage-form-page/index.vue`
                  )
              },
              {
                path: 'biaya-lainnya',
                name: 'Biaya Lainnya',
                props: true,
                meta: {
                  breadcrumb: 'Biaya Lainnya'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/other-cost-form-page/index.vue`
                  )
              },
              {
                path: 'biaya',
                name: 'Biaya',
                props: true,
                meta: {
                  breadcrumb: 'Biaya'
                },
                component: () =>
                  useLazyloadComponent(
                    `${path.industry}/pages/cost-form-page/index.vue`
                  )
              }
            ]
          }
        ]
      },

      {
        path: 'kbli',
        name: 'Data KBLI',
        meta: {
          breadcrumb: 'Data KBLI'
        },
        component: () =>
          useLazyloadComponent(`@core/layouts/DefaultLayout.vue`),
        children: [
          {
            path: '',
            name: 'Data KBLI',
            meta: {
              breadcrumb: 'Data KBLI'
            },
            component: () =>
              useLazyloadComponent(
                `${path.classification}/pages/default-page/index.vue`
              )
          },
          {
            path: 'import',
            name: 'Import  KBLI',
            meta: {
              breadcrumb: 'Import KBLI'
            },
            component: () =>
              useLazyloadComponent(
                `${path.classification}/pages/import-page/index.vue`
              )
          }
        ]
      },

      {
        path: 'satuan',
        name: 'Satuan',
        meta: {
          breadcrumb: 'Satuan'
        },
        component: () =>
          useLazyloadComponent(
            `${path.unit}/pages/default-page/index.vue`
          )
      },

      {
        path: 'komoditas',
        name: 'Komoditas',
        meta: {
          breadcrumb: 'Komoditas'
        },
        component: () =>
          useLazyloadComponent(
            `${path.commodity}/pages/default-page/index.vue`
          )
      },

      {
        path: 'wilayah-pemasaran',
        name: 'Wilayah Pemasaran',
        meta: {
          breadcrumb: 'Wilayah Pemasaran'
        },
        component: () =>
          useLazyloadComponent(
            `${path.marketingArea}/pages/default-page/index.vue`
          )
      },

      {
        path: 'pembiayaan',
        name: 'Pembiayaan',
        meta: {
          breadcrumb: 'Pembiayaan'
        },
        component: () =>
          useLazyloadComponent(
            `${path.finance}/pages/default-page/index.vue`
          )
      },

      {
        path: 'map',
        name: 'Map',
        meta: {
          breadcrumb: 'Map'
        },
        component: () =>
          useLazyloadComponent(
            `${path.map}/pages/default-page/index.vue`
          )
      },

      {
        path: 'pengaturan',
        name: 'Pengaturan Web',
        meta: {
          breadcrumb: 'Pengaturan Web'
        },
        component: () =>
          useLazyloadComponent(
            `${path.meta}/pages/default-page/index.vue`
          )
      },

      {
        path: 'user',
        name: 'Data Pengguna',
        meta: {
          breadcrumb: 'Data Pengguna'
        },
        component: () =>
          useLazyloadComponent(
            `${path.user}/pages/default-page/index.vue`
          )
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const isRequiresAuth = to.matched.some(
    (record) => record.meta.requiresAuth
  );
  const { getUser } = useAuthStore(pinia);
  if (isRequiresAuth) {
    if (await getUser()) next();
    else {
      alert('you dont have access!');
      next('/masuk');
    }
  } else {
    window.scrollTo(0, 0);
    next();
  }
});

export default router;
