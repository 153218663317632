import useErrorMessageHandler from '@/@core/helpers/errorMessageHandler';
import { useCallApi } from '@/@core/services/axiosCall';
import {
  reactive,
  Ref,
  ref,
  toRefs,
  unref
} from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useDistrictStore = defineStore('district', () => {
  const state = reactive({
    total: 0,
    districts: []
  });

  async function getManyDistrict() {
    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: '/districts'
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    state.districts = result.districts;
    state.total = result.count;

    return result;
  }

  async function postDistrict(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: '/districts',
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function putDistrict(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'put',
      url: '/districts/' + data.id,
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function deleteDistrict(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'delete',
      url: '/districts/' + data.id
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }
  return {
    ...toRefs(state),
    getManyDistrict,
    postDistrict,
    deleteDistrict,
    putDistrict
  };
});
