

































import { computed, defineComponent, onMounted, ref, unref } from '@vue/composition-api';
import KSnackbar from './@core/components/other/KSnackbar.vue';
import KPageTransition from './@core/components/page/KPageTransition.vue';
import { useUiStateStore } from './@core/services/uiStateStore';
import { useUnitStore } from './modules/unit/services/store';
import { useDistrictStore } from './modules/district/services/store';

export default defineComponent({
  components: { KPageTransition, KSnackbar },
  name: 'App',
  setup() {
    const uiStateStore = useUiStateStore();
    const unitStore = useUnitStore();
    const districtStore = useDistrictStore();

    const isBooting = ref(true)

    const loadMasterData = async () => {
      setTimeout(() => {
        isBooting.value = false
      }, 300)
      await unitStore.getManyUnit();
      await districtStore.getManyDistrict();
      uiStateStore.setMasterDataLoaded()
    };

    onMounted(() => {
      uiStateStore.initInstallPrompt()
      loadMasterData()
  });

    return {
      uiStateStore,
      isBooting
    };
  }
});
