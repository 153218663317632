export default function useFixRouter(router: any) {
    // Solve error about route to same page & error while redirected because of routerGuard
    let originalPush = router.prototype.push
    // @ts-ignore: Unreachable code error
    router.prototype.push = function push(location: any, onResolve: Function, onReject: any): any { 
        if (onResolve || onReject)
            return originalPush.call(this, location, onResolve, onReject)
        // @ts-ignore: Unreachable code error
        return originalPush.call(this, location).catch((err) => {
            if (router.isNavigationFailure(err)) {
                // resolve err
                return err
            }
            // rethrow error
            return Promise.reject(err)
        })
    }
}

export {
    useFixRouter
}
