import { defineStore } from "pinia"
import { reactive, toRefs } from "@vue/composition-api"
import { removeItem } from "../helpers/arrayHelper"

export type Snackbar = {
    id?: string
    color?: string
    timeout?: number
    message: string
    arrayMessage?: any[]
}

type UiState = {
    showDrawer: boolean | null
    activeNavigation: boolean | null
    snackbars: Snackbar[]
    isMasterDataLoaded: boolean
    isInstallable: boolean
    deferredPrompt: any
}

export const useUiStateStore = defineStore('ui-state', () => {
    const state = reactive<UiState>({
        showDrawer: null,
        activeNavigation: null,
        snackbars: [],
        isMasterDataLoaded: false,
        isInstallable: false,
        deferredPrompt: null
    })

    const setMasterDataLoaded = () => {
        state.isMasterDataLoaded = true
    }

    const toggleDrawer = () => {
        const isActive = !!state.showDrawer
        state.showDrawer = isActive ? false : true as any
    }

    function setSnackbars(snackbars: Snackbar[]) {
        state.snackbars = [...snackbars]
    }

    function createSnackbar(snackbar: Snackbar) {
        const id: string = Math.random().toString(36) + Date.now().toString(36).substr(20)
        setSnackbars([...state.snackbars, { ...snackbar, id }])
    }

    function removeSnackbar(snackbar: Snackbar) {
        const updatedSnackbars: Snackbar[] = removeItem([...state.snackbars], (<any>snackbar).id)
            
        setSnackbars(updatedSnackbars)
    }

    
    const initInstallPrompt = () => {
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            state.deferredPrompt = e;
            // Update UI notify the user they can install the PWA
            state.isInstallable = true
            // displayButton.value = true
            // Optionally, send analytics event that PWA install promo was shown.
        });
    }

    const resetDefferedPrompt = () => {
        state.deferredPrompt = null
    }

    return {
        ...toRefs(state),
        toggleDrawer,
        createSnackbar,
        removeSnackbar,
        setMasterDataLoaded,
        initInstallPrompt,
        resetDefferedPrompt
    }
})
    