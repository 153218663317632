import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#9A36AF',
                // primary: '#9A62FD',
                // info: '#0049C6',
                // error: '#D32A2A',
                // warning: '#FF8B26',
                // navigationBar: '#1C3FAA',
                // navigationBar: '#FFFFFF',
                // background: "#F1F5F8",
                // primaryTextColor: '#5e5669',
                // tableHeader: "#F1F5F8",
                // background: "#FFFFFF",
                // unselected: "#909090",
                // textPrimary: "#0D0D0D",
                // textSecondary: "#606060"
                background: "#EEF0F4",
                'input-background': "#EDECEF",
            },
            dark: {
                //
            },
        },
    },
});
