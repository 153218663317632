import useErrorMessageHandler from "@/@core/helpers/errorMessageHandler"
import { useCallApi } from "@/@core/services/axiosCall"
import { useParseJwt } from "@/@core/services/jwtParser"
import router from "@/router"
import { computed, Ref, ref } from "@vue/composition-api"
import { defineStore } from "pinia"

export const useAuthStore = defineStore('auth', () => {
    const user: Ref<any> = ref(null)
    const role = computed(() => user.value?.role?.name)

    async function signIn(payload: any) {
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'post',
            url: '/auth/signin',
            data: payload
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return false
        }

        user.value = result.user

        return true
    }

    async function signUp(payload: any) {
        const {
            errorMessage,
            error,
            result
        } = await useCallApi({
            method: 'post',
            url: '/auth/signup',
            data: payload
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return false
        }

        user.value = result.user

        return true
    }

    async function signOut() {
        localStorage.removeItem('adiId')
        router.push('/masuk')
    }

    async function getUser() {
        const token = localStorage.getItem('adiId')
        const { userId } = useParseJwt(token)

        if (!userId) return null
        
        const {
            errorMessage,
            result
        } = await useCallApi({
            method: 'get',
            url: '/users/' + userId,
        })
        
        if (errorMessage) console.error(errorMessage)
        if (result) user.value = result.user

        return user.value
    }
  
    return {
        user,
        role,
        signIn,
        signUp,
        getUser,
        signOut
    }
  })
  