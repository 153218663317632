














import { isMobile } from '@/@core/helpers/useBreakpoint'
import { defineComponent, ref, Ref, watch } from '@vue/composition-api'

export default defineComponent({
    name: 'KPageTransition',
    setup(props, { root }) {
        const usedTransition: Ref<string> = ref('router-view')
        const usedMode: Ref<string> = ref('')

        function afterEnter() {
            window.scrollTo(0, 0);
        }

        function afterLeave() {
            // this.$store.commit("meta/SET_PAGE_TRANSITION", "default");
            // console.log('iam leaving');
        }

        watch(() => root.$route, (to, from) => {
            const toPath = to.path.split("/")
            const fromPath = from.path.split("/")
            const isOnApp = toPath.includes('app')
            
            const index = isOnApp ? 2 : 1

            const toRootPath = toPath[index]
            const fromRootPath = fromPath[index]

            const toDepth = toPath.length;
            const fromDepth = fromPath.length;

            const isDifferentRootPath = toRootPath !== fromRootPath

            if (isDifferentRootPath) usedTransition.value = "";
            else {
                usedTransition.value = toDepth < fromDepth ? "router-view-back" : "router-view";
                usedMode.value = toDepth < fromDepth ? "" : "in-out";
            }
        })

        return {
            usedTransition,
            usedMode,
            afterEnter,
            afterLeave,
            isMobile
        }
    }
})
