import { useMediaQuery, useBreakpoints, breakpointsTailwind } from "@vueuse/core"

// const isMobile = useMediaQuery('(max-width: 600px)')
const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smaller("sm")

export {
    isMobile,
    breakpoints
}
